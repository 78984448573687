<template>
  <div>
    <div class="text-center my-2">
      <span class="font-weight-bold text-caption">{{
        indications.join(', ')
      }}</span>
    </div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.id">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in tableData"
            :class="regex.test(item.groupName.value) ? 'green--text' : ''"
            :key="item.id"
          >
            <CostItem
              v-for="el in Object.keys(item)"
              :formatter="formatterItem(el)"
              :key="el"
              :item="item[el]"
            />
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div
      class="text-caption font-weight-bold"
      v-for="footer in footers"
      :key="footer.id"
    >
      <span>{{ footer }}</span>
    </div>
    <span class="v-card__title">Расчёты</span>
    <v-container>
      <v-row dense>
        <v-col cols="3" class="text-subtitle-2">
          <span>Название предлагаемого ЛП</span>
        </v-col>
        <v-col cols="9" class="d-flex flex-wrap"
          ><AutoGrowTextEditor
            v-model="name"
            :outlined="editMode"
            @input="doChange(false)"
            @blur="doBlur"
            label="название предлагаемого препарата"
            persistent-hint
            dense
            :rows="1"
          ></AutoGrowTextEditor
        ></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3" class="text-subtitle-2">
          Показание (в творительном падеже (кем? чем?))</v-col
        >
        <v-col cols="9" class="d-flex flex-wrap">
          <AutoGrowTextEditor
            v-model="indication"
            :outlined="editMode"
            @input="doChange(false)"
            @blur="doBlur"
            label="показание (например, у взрослых пациентов с рецидивирующей или рефрактерной множественной миеломой)"
            persistent-hint
            dense
            :rows="1"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3" class="text-subtitle-2">
          Режим дозирования по ИМП
        </v-col>
        <v-col cols="9" class="d-flex flex-wrap">
          <AutoGrowTextEditor
            v-model="calculation"
            :outlined="editMode"
            @input="doChange(false)"
            @blur="doBlur"
            label="например 1800 мг 1 раз в неделю с 1-ой по 8-ю неделю, 1 раз в 2 недели с 9-ой по 24-ю недели, 1 раз в 4 недели с 25-ой недели до прогрессирования"
            persistent-hint
            dense
            :rows="1"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :items="combinations"
      :headers="costHeaders"
      show-expand
      single-select
      single-expand
      item-key="id"
      :expanded.sync="expandedCombinations"
      disable-pagination
      hide-default-footer
      no-data-text="Информация о расчёте затрат отсутствует"
    >
      <template #[`item.id`]="{ item }">
        <span v-if="item.id === 0" class="text--disabled"
          >Стоимость предлагаемого препарата</span
        >
        <span v-else class="text--disabled">Комбинация №{{ item.id }}.</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="td-expanded pa-2">
          <CostEditor
            :value="item"
            @change="
              (item, force) => {
                doChangeItem(item, force)
              }
            "
            @delete="deleteCostItem(item.id)"
            @blur="doBlur"
            :readonly="readonly"
            :main-name="mnn"
            :editMode="editMode"
          />
        </td>
      </template>
      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <td class="text-right">
          <v-btn
            icon
            @click="expand(!isExpanded)"
            class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }"
          >
            <v-icon>mdi-pencil-circle-outline</v-icon>
          </v-btn>
        </td>
      </template>
      <template #footer v-if="editMode">
        <div class="d-flex pt-3 pl-2 mb-2">
          <v-btn
            color="accent"
            fab
            small
            :disabled="!!readonly"
            title="Добавить комбинацию препаратов"
            @click="addCombination()"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { copyObject } from '@/lib/objects'
import CostItem from './CostItem'
import { toMoney } from '@/lib/strUtils'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import { DEFAULT_COMBINATION } from '../const'
import CostEditor from './CostEditor.vue'

const regex = new RegExp(`^\\s*Предлагаемый*\\s*ЛП*`, 'gm')

export default {
  name: 'CostTable',
  components: { CostItem, AutoGrowTextEditor, CostEditor },
  data: () => ({
    indications: [],
    footers: [],
    tableData: [],
    headers: [],
    regex,
    activeTab: 0,
    name: '',
    indication: '',
    calculation: '',
    combinations: [],
    expandedCombinations: [0],
    costHeaders: [
      {
        text: '',
        value: 'id',
        sortable: false,
      },
      { text: '', value: 'data-table-expand' },
    ],
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    calculations: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    mnn: { type: String },
    condition: { type: String },
  },
  watch: {
    value: 'init',
  },
  methods: {
    init() {
      this.tableData = copyObject(this.value?.tableData) || []
      this.indications = this.value?.valuesIndications || []
      this.footers = this.value?.valuesFooters || []
      this.headers = this.value?.headers || []

      this.name = this.calculations?.name || ''
      this.indication = this.calculations?.indication || ''
      this.calculation = this.calculations?.calculation || ''

      this.combinations =
        this.calculations?.combinations?.map((item, index) => ({
          id: index,
          ...item,
        })) || []
      if (this.combinations.length <= 0) this.addCombination()
    },
    doChange(force = false) {
      const { name, indication, calculation, combinations } = this
      this.$emit(
        'change',
        {
          name,
          indication,
          calculation,
          combinations,
        },
        force
      )
    },
    doBlur() {
      this.$emit('blur')
    },
    expand(item = null) {
      this.expandedCombinations = item ? [item] : []
    },
    addCombination() {
      this.combinations.push({
        ...DEFAULT_COMBINATION(),
        id: this.combinations.length,
      })

      this.$nextTick(() => {
        const lg = this.combinations?.length || 0
        this.expand(this.combinations[lg - 1])
      })

      this.doChange(true)
    },
    doChangeItem(item, force) {
      const idx = this.combinations.findIndex(el => el.id === item.id)
      this.combinations[idx] = item
      this.doChange(force)
    },
    deleteCostItem(id) {
      const findIndex = this.combinations.findIndex(item => item.id === id)
      if (findIndex >= 0) {
        this.combinations.splice(findIndex, 1)
        this.combinations = this.combinations.map((item, index) => ({
          ...item,
          id: index,
        }))
        this.doChange(true)
        this.expand(null)
      }
    },
    formatterItem(key) {
      if (key === 'patientPopulation')
        return function (value) {
          return Number(value) ? Number(value).toLocaleString('ru') : value
        }
      if (key === 'requiredEiNumber')
        return function (value) {
          return Number(value) ? Number(value).toLocaleString('ru') : value
        }
      if (key === 'priceEiNumber' || key === 'priceTherapy') {
        return function (value) {
          if (isNaN(Number(value))) return value
          return toMoney(value, true)
        }
      }
      if (key === 'mnn') {
        return function (value) {
          if (!value.richText) return value
          const richText = value.richText[0].text || ''
          const richNum = value.richText[1].text || ''

          return richText + ' ' + richNum
        }
      }
    },
    //Рекурсивно проверяем на присутствие значения в следующей строке, если значения нет, то добавляем idx объединения
    // getIdx(tableData, i, key, count = 1) {
    //   if (
    //     tableData[i]?.[`${key}`]?.value &&
    //     tableData[i + count] &&
    //     !tableData[i + count]?.[`${key}`]?.value
    //   ) {
    //     return this.getIdx(tableData, i, key, count + 1)
    //   } else return count
    // },
  },
  created() {
    this.init()
  },
}
</script>
